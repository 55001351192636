<template>
    <div class="MyTableRow">
        <div class="MyTableRow__cell text">{{ text }}</div>
        <div :class="['MyTableRow__cell', 'value', {ellipsis}]">
            <slot name="value">{{ value }}</slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "MyTableRow",
        props: {
            text: String,
            value: [String, Number],
            // 文字折叠
            ellipsis: Boolean
        }
    }
</script>

<style lang="scss" scoped>
    .MyTableRow {
        border-bottom: 1px solid #ECEEF1;
        display: flex;
        font-size: 14px;
        &__cell {
            padding: 16px 16px 16px 0;
            &.text {
                width: 30%;
                color: #8F9295;
                font-weight: 600;
            }
            &.value {
                width: 70%;
                color: #101010;
                &.ellipsis {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
</style>